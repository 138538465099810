* {
    font-family: 'Athiti', sans-serif;
}

:root {
    --roxo: #5B1B70;
    --amarelo: #FFBC00;
    --cinza: #E8E8E8;
    --preto: #454545;
}

html,
body {
    height: 100%;
}

.btn {
    margin-right: 5px;
}

.markedAsDone {
    text-decoration: line-through;
    color: #777
}

.tableActions {
    width: 105px;

}
.navbar{
    position: relative;
    margin-bottom: 0px !important
}

.todoForm {
    padding-bottom: 60px;
}

.navbar-toggle .icon-bar {
    background-color: var(--roxo);
}

.navbar-toggle {
    border: 1px solid var(--roxo);
}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand,
.dropdown-toggle {
    margin-left: 15px;
    font-size: 18px;
    font-weight: bold;
}

.carousel-inner>.item>img {
    margin: auto;
    width: 95%
}

#carouseltocomprando {
    margin-top: -160px;
}

#fixa {
    z-index: 10;
    position: absolute;
}

#parte2 {
    margin-top: 70px;
}

.fl_right {
    float: right;
}

.fl_left {
    float: left;
}

.mt25 {
    margin-top: 20px;
}

.bota {
    cursor: pointer;
    color: var(--roxo);
    /* background: var(--amarelo); */
}

.clear {
    clear: both;
}

.icone_card {
    width: 55px;
    background: #FFBC00;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    /* padding: 7px; */
    display: inline-block;

    height: 53px;
    padding: 9px;
    margin: 20px auto 0;
    text-align: center;
    display: block;
}

.landing_compra {
    color: #6F6F6F;
    line-height: 17px;
    text-align: left;
    display: block;
    margin: 0 auto 27px;
    float: left;
    width: 100%
}

.texto_padrao {
    color: #6F6F6F;
    line-height: 17px;
    text-align: left;
    display: block;
    margin: 0 auto 130px;
    float: left
}

.produtos_botas button {
    border-radius: 35px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 12px 36px 12px 36px;
    display: block;
    margin: 0 auto;
    color: #5B1B70;
    background: #FFBC00;
    text-align: center;
    width: 270px;
    margin-bottom: 50px;
}

.produtos_botas button:hover {
    color: var(--amarelo);
    background: var(--roxo)
}

.invert {
    transform: rotate(180deg);
    width: 20px;
}

.invert img:hover {
    color: var(--amarelo)
}

#myBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: var(--amarelo);
    color: var(--roxo);
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
}

.mt50 {
    margin-top: 50px
}

.mt20 {
    margin-top: 20px;
}

.mb50 {
    margin-bottom: 50px
}

.mb20 {
    margin-bottom: 20px;
}

.ml20 {
    margin-left: 20px;
}

.oQueCompram {
    padding-top: 100px;
    color: var(--roxo);
    text-align: center;
    font-size: 2em;
}

.button-blind {
    background-color: var(--amarelo);
    /* outline: var(--amarelo); */
    border: var(--amarelo);
    margin-right: 10px;
}

.rounded-circle {
    border-radius: 50%;
    float: left;
}

.container-loading {
    margin-top: 10%;
}

.filter-tab select,
.filter-tab input {
    border-radius: 35px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 8px 30px 8px 30px;
    /* display: block; */
    margin: 0 5px auto;
    color: var(--roxo);
    background: white;
    width: 100%;
    max-width: 500px;
    border: 1px solid #5B1B70;
    margin-top: 25px;
}

.full-wd {
    width: 100%;
    padding: 0;
}

#t {
    overflow-x: hidden;
    padding-top: 100px;
    color: var(--roxo);
    background-color: white;
    text-align: center;
    font-size: 1.2em;
    padding-bottom: 20px;
    line-height: 15px;
    padding-top: 40px;
    margin: 0;
    margin-bottom: 35px;

}

.searcher button {
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 8px 12px 8px 12px;
    display: block;
    margin: 0px;
    color: #5B1B70;
    background: #FFBC00;
    text-align: center;
    margin-top: 20px;
}

.detalhes-page {
    margin: 0x 100px 100px 100px;
    padding-top: 50px;
    background: white;
}

.detalhes-page b {
    color: var(--roxo);
    text-align: center;
    line-height: 35px;
    /* padding-top: 100px; */
    /* margin-top: 150px; */
}

.detalhes-page i {
    color: var(--roxo);
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    /* padding-top: 100px; */
    /* margin-top: 150px; */
}

.detalhes-page h1 {
    color: var(--roxo);
    text-align: center;
    line-height: 35px;
    /* padding-top: 100px; */
    /* margin-top: 150px; */
}

.b-fino {
    font-weight: 400;
    font-size: 16px;
}

.super-text {
    border-radius: 35px;
    font-weight: 400;
    font-size: 1.2em;
    padding: 0px 15px 0px 15px;
    margin: 0px 15px;
    color: var(--roxo);
    background: var(--amarelo);
}

#carouseldetails {
    width: 100%;
    height: 380px;
}

#carouseldetails>.carousel-inner>.item img {
    max-width: 500px;
    max-height: 500px;
    border: 2px solid var(--amarelo);
    border-radius: 20px 0px 20px 20px;
    padding: 5px;
    background-color: white;
}

#carouseldetails>.carousel-indicators .active {
    background-color: var(--roxo);
    margin-left: 20px;
}

#carouseldetails>.carousel-indicators li {
    border: 1px solid var(--roxo);
    margin-left: 20px;
}

input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    
}
input[type=number] { 
   -moz-appearance: textfield;
   appearance: textfield;

}

.jumbotron,
.jumbotron-2 {
    border-radius: 20px;
    height: 320px;
    margin-left: 5px;
    background-color: var(--cinza);
}

.jumbotron-profile {
    border-radius: 20px;
    height: 100%;
    width: 100%;
    padding: 0;
    margin-left: 5px;
    background-color: var(--cinza);
    padding: 20px 0px 5px 5px;
    display: flex;
    background: white;
}

.jumbotron label,
.jumbotron-2 label,
.jumbotron-profile label {
    color: var(--roxo)
}

.jumbotron h3 {
    font-weight: 500;
    font-size: 22px;
    word-wrap: break-word;
}

.jumbotron-2 {
    background: white;
    /* height: 100vh; */
}

.box-450>.icone_card {
    margin-right: 100px;
    margin-top: -5px;
    margin-bottom: 20px;
}

.seta-down {
    width: 50px;
}

.seta-down-mini {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 30px;
}

.react-tabs__tab {
    color: var(--roxo);
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    background-color: var(--cinza);
    margin-left: 5px;
    margin-right: 1px;
}

.react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    border-radius: 10px 10px 0 0;
}

.form-post {
    background: transparent;
    border: none;
    border-bottom: 2px solid var(--roxo);
    border-radius: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
}

input[type=file] {
    border: none;
    border-bottom: 2px solid var(--roxo);
    border-radius: 0px;
}

#img-capa {
    visibility: hidden;
}

#capa {
    border: 2px solid var(--amarelo);
    border-radius: 10px;
    margin: 0;
    margin-top: -20px;
}

.miniatura {
    /* background-image: url('https://cdn.icon-icons.com/icons2/1347/PNG/512/photocamerastrokesymbol_87746.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80px;
    border: 2px solid var(--roxo);
    border-radius: 20px;
    margin-top: 10px;
}

.label-imagem {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80px;
    border: 2px solid var(--roxo);
    border-radius: 20px;
    margin-top: 10px;
}

input[type=text]::placeholder,
input[type=number]::placeholder,
input[type=file]::placeholder {
    font-weight: 600;
}

textarea {
    border: 2px solid var(--roxo);
    border-radius: 10px;
    margin: 20px 10px 20px 0px;
    width: 100%;
    font-weight: 600;
}

.categoria-select {
    border-radius: 35px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 8px 30px 8px 30px;
    /* display: block; */
    margin: 0 5px auto;
    color: var(--roxo);
    background: white;
    width: 100%;
    max-width: 500px;
    border: 1px solid #5B1B70;
    margin-top: 10px;
}

.postar_btn button {
    border-radius: 35px;
    font-weight: 600;
    font-size: 1.20m;
    padding: 12px 36px 12px 36px;
    display: block;
    margin: 10px auto;
    color: var(--amarelo);
    background: var(--roxo);
    text-align: center;
    /* width: 270px; */
    /* margin-top: 20px; */
    /* margin-bottom: 50px; */
}

.postar_btn button:hover {
    color: var(--roxo);
    background: var(--amarelo)
}

.excluir{
    margin-left: 10px;
}

/*RESPONSIVO*/
/*720px*/
@media (min-width: 45em) {
    .react-multi-carousel-list .icone_card {
        width: 55px;
        background: #FFBC00;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        /* padding: 7px; */
        display: inline-block;

        height: 53px;
        padding: 9px;
        margin: 20px 65px 0;
        text-align: center;
        display: block;
    }

    .react-multi-carousel-list .landing_details button {
        /* margin: 10px auto 110px; */
        margin: 10px 25px 70px;
    }

    .box-450 {
        width: 450px;
        padding-left: 100px;
        z-index: 999;
    }

    #carouseldetails {
        width: 400px;
        /* margin-left: 20px; */
        height: 450px;
    }

    .jumbotron,
    .jumbotron-2 {
        height: 260px;
        margin-top: 50px;
        padding-left: 100px;
    }

    .jumbotron-profile {
        height: 500px;
        margin-top: 25px;
        padding: 10px 20px 20px 20px;
        /* z-index: 999; */
        border-radius: 25px;
        display: inherit;
    }

    .jumbotron h3 {
        margin-top: 0px;
    }

    .react-tabs__tab {
        width: 185px;
    }

    .mais-vendido {
        background-color: var(--cinza);
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 25px;
        margin-top: -10px;
    }

    .mais-vendido>.square-2 {
        background-color: var(--roxo);
        color: #fff;
    }

    .mais-vendido>.losang-2 {
        background-color: var(--roxo);
    }

    .mais-vendido>img {
        margin-left: 35px;
        margin-top: -10px;
        position: absolute;
    }

}

.produtos_detalhes button {
    border-radius: 35px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 12px 36px 12px 36px;
    display: block;
    margin: 0 auto;
    color: var(--amarelo);
    background: var(--roxo);
    text-align: center;
    /* width: 270px; */
    margin-top: 80px;
    margin-bottom: 50px;
}

.produtos_detalhes button:hover {
    color: var(--roxo);
    background: var(--amarelo)
}

.detalhes-tabela,
.table-profile {
    text-align: center;
}

.table-jumbotron {
    height: 400px;
    width: 100%;
    overflow-y: auto;
}

.table-status>tbody>tr {
    cursor: default;
}

.table-status>thead>tr>th {
    text-align: center;
    color: var(--roxo);
    cursor: default;
}

.table-status b {
    font-weight: 600;
    color: var(--preto);
}

.table-status button {
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 5px 26px 5px 26px;
    border: #5B1B70 2px solid;
    /* margin: 10px auto 110px; */
    color: #5B1B70;
    background: transparent;
    text-align: center;
}


.table-status button:hover {
    color: var(--amarelo);
    background: #5B1B70;
}

.table-profile>tbody>tr>td,
.table-profile>thead>tr>th {
    border-bottom: 2px solid var(--amarelo);
    border-top: 0;
}

.carousel-container {
    margin-bottom: 50px;
}

.bg-grey {
    background-color: var(--cinza);
    height: 100vh;
    /* display: table; */
    position: relative;
}

.bg-white {
    background-color: #fff;
    height: 100vh;
    display: table;
}

.topo h1 {
    text-align: left
}

.topo b {
    color: var(--preto);
}

.bly {
    border-left: 2px solid var(--amarelo)
}

.circle {
    background-color: var(--preto);
    border-radius: 100px;
    width: 150px;
    height: 150px;
    line-height: 140px;
    font-size: 75px;
    color: white;
    /* margin-left: 25%; */
    display: inline-block;
}

.square {
    background-color: var(--preto);
    border-radius: 15px;
    width: 150px;
    height: 150px;
    line-height: 140px;
    font-size: 75px;
    color: white;
    /* margin-left: 25%; */
    display: inline-block;
}

.square-2 {
    background-color: var(--amarelo);
    border-radius: 15px;
    /* width: 150px; */
    height: 150px;
    /* line-height: 140px; */
    /* font-size: 75px; */
    color: var(--roxo);
    margin-top: 10px;
}


.square-2 b {
    font-size: 75px;
}

.square-2 p {
    font-weight: 400;
    font-size: 25px;
}

.mais-vendido {
    background-color: var(--cinza);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 25px;
    cursor: default;
}

.mais-vendido>.square-2 {
    background-color: var(--roxo);
    color: #fff;
}

.mais-vendido>.losang-2 {
    background-color: var(--roxo);
}

.mais-vendido>img {
    margin-top: -10px;
    position: absolute;
}

.losang {
    background-color: var(--preto);
    border-radius: 15px;
    width: 150px;
    height: 50px;
    /* line-height: 50px; */
    font-size: 30px;
    color: white;
    /* margin-left: 25%; */
    display: inline-block;
}

.losang-2 {
    background-color: var(--preto);
    border-radius: 15px;
    border-style: none;
    width: 100%;
    /* height: 50px; */
    /* line-height: 50px; */
    font-size: 30px;
    color: white;
}

.table-historico {
    height: 285px;
    width: 100%;
    overflow-y: auto;
}

.flex {
    display: flex
}

.modal-dialog {
    margin-top: 20%
}

.spinner {
    height: 100vh;
    margin-top: 0px;
}

.termos > p,
.termos > section > p {
    font-weight: normal !important;
}

.termos{
    text-align: justify;
}
/* --------------------------------- Custom emeu */

h1 {
    font-size: 2em;
    font-weight: 600;
}

p {
    /* font-size: 0.9em; */
    font-weight: 600;
}

input {
    width: 280px;
    border-radius: 25px;
    padding: 13px 19px 13px 25px;
    border: none;
}

.container {
    margin: 0;
    width: 100%;
}

.content {
    margin: 0 5%;
    width: 90%;
}

.landing_header {
    position: fixed;
}

.landing_back_header {
    background: #FFBC00;
    /* padding-bottom: 65px; */
    /* margin-top: 10px; */
    /* display: block; */
    width: 100%;
    /* position: relative; */
    /* position: fixed; */
    text-decoration: none;
}

.landing_header li {
    font-size: 0.9em;
    font-weight: 600;
    width: 105%;
    padding-top: 20px;
    text-align: left;
    display: inline-block;
}

.landing_back_header a,
.landing_header a {
    color: #5B1B70;
    text-decoration: none;
}

.landing_back_header img {
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -30px;
}

.landing_comprando {
    background: #FFBC00;
    padding-bottom: 220px;
}

.landing_comprando img {
    width: 110px;
    display: block;
    margin: 0 auto;
    padding-top: 70px;
}

.landing_comprando h1 {
    width: 300px;
    color: #5B1B70;
    text-align: center;
    line-height: 35px;
    padding-top: 40px;
    display: block;
    margin: 0 auto;
}

.landing_comprando p {
    color: #5B1B70;
    padding-top: 30px;
    line-height: 17px;
    text-align: center;
    margin: 0 10%;
    padding-bottom: 50px;
}

.landing_comprando input {
    display: block;
    margin: 20px auto;
}

.landing_comprando button {
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 8px 30px 8px 30px;
    /* display: block; */
    margin: 0 5px auto;
    color: #FFBC00;
    background: #5B1B70;
    width: 140px;
    border: 1px solid #5B1B70;
}

.landing_comprando button:hover {
    color: #5B1B70;
    background: #FFBC00;
}

.landing_imagem_meio img:first-of-type {
    display: block;
    margin: -150px auto 0;
    border-radius: 15px;
}

.landing_seta img {
    width: 50px;
    /* display: block; */
    left: 48%;
    margin-right: -45%;
    margin: -30px auto 0;
}

.landing_iphone img {
    display: block;
    margin: 0px auto 0;
    max-width: 70%;
}

.landing_chegouahora {
    padding-bottom: -100px;
}

.landing_chegouahora h1 {
    font-size: 1.8em;
    font-weight: 500;
    color: #FFBC00;
    line-height: 30px;
    width: 65%;
    ;
    display: block;
    margin: 50px auto 20px;
}

.landing_chegouahora p {
    line-height: 17px;
    color: #6F6F6F;
    width: 60%;
    display: block;
    margin: 0 auto 50px;
}

.landing_funciona_back {
    background: #E8E8E8;
    padding-top: 80px;
    margin: -50px 0;
}

.landing_funciona {
    padding-bottom: 40px;
}

.landing_funciona h1 {
    color: #5B1B70;
    max-width: 300px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 30px;
}

.landing_funciona p {
    color: #6F6F6F;
    line-height: 17px;
    text-align: left;
    display: block;
    margin: 0 auto 130px;
    float: left;
}

.landing_article_first p:first-of-type {
    width: 280px;
    border-radius: 45px;
    padding: 10px 40px 10px 40px;
    border: none;
    background: #fff;
    display: block;
    margin: 0 auto;
}

.landing_article {
    width: 280px;
    border-radius: 45px;
    padding: 10px 20px 10px 20px;
    border: none;
    background: #fff;
    display: block;
    margin: 0 auto;
    min-height: 74px;
}

.landing_article p {
    line-height: 18px;
    font-weight: 400;
    width: 154px;
    display: inline-block;
    font-size: 0.8em;
}

.landing_tudo {
    padding-bottom: 50px;
}

.landing_article p:first-of-type {
    font-size: 1.4em;
    font-weight: 600;
    color: #5B1B70;
    width: 30px;
    margin-top: 15px;
}

.landing_capsula img {
    width: 90px;
    display: block;
    margin: 90px auto 20px;
}

.landing_capsula_diferente img {
    display: block;
    margin: 0 auto 20px;
    width: 90px;
}

.landing_final_div button {
    border-radius: 35px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 12px 36px 12px 36px;
    display: block;
    margin: 0 auto;
    color: #5B1B70;
    background: #FFBC00;
    text-align: center;
    width: 220px;
}

.landing_final_div button:hover {
    color: #FFBC00;
    background: #5B1B70;
}

.landing_final_div img {
    width: 40px;
    display: block;
    margin: 50px auto;
}

.landing_comprando_articles {
    padding-top: 120px;

}

.landing_comprando_articles h1 {
    color: #5B1B70;
    text-align: left;
    margin-bottom: 30px;
    line-height: 31px;
}

.landing_comprando_articles p {
    color: #6F6F6F;
    line-height: 17px;
    text-align: left;
    /* margin: 0 1%; */
    margin: 0px 0px;
    float: left;
}

.caixaroxa {
    background: #5B1B70;
    /* width: 175px; */
    width: 190px;
    border-radius: 12px;
    padding: 20px 36px 20px 36px;
    padding: 10px 36px 10px 36px;
    text-align: center;
    display: block;
    margin: 10px auto 0;
    line-height: 34px;
    min-height: 170px;
    max-height: 100%;
    
}

.caixaroxa h1 {
    color: #FFBC00;
    font-weight: 500;
    /* font-size: 1.4em; */
    /* font-size: 1.7em; */
    line-height: 23px;
    word-break: break-word;
}

.caixacinza {
    background: #E8E8E8;
    /* width: 175px; */
    width: 190px;
    border-radius: 12px;
    /* padding: 0px 36px 10px 36px; */
    padding: 0px 20px 10px 20px;
    text-align: left;
    display: block;
    margin: 0 auto 0;
    /* line-height: 34px; */
}

.caixacinza bold {
    color: #5B1B70;
    /* font-size: 0.7em; */
    font-weight: 800;
    text-align: left;
    /* margin-right: 20px; */
    line-height: 12px;
}

.caixacinza span {
    border-radius: 25px;
    font-weight: 800;
    font-size: 1.2em;
    display: block;
    color: #5B1B70;
    background: #FFBC00;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.caixacinza p {
    font-size: 1em;
    color: #6F6F6F;
}

.first_caixa {
    padding-top: 20px;
}

.minicaixa {
    /* margin: 23px 0; */
    margin: 20px 0;
}

.landing_details button {
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 5px 26px 5px 26px;
    border: #5B1B70 2px solid;
    display: block;
    /* margin: 10px auto 110px; */
    margin: 10px auto 70px;
    color: #5B1B70;
    background: transparent;
    text-align: center;
    width: 140px;
}


.landing_details button:hover {
    color: var(--amarelo);
    background: #5B1B70;
}

.produtos_bota {
    border-radius: 55px;
    font-weight: 600;
    font-size: 1em;
    padding: 15px 20px 15px 20px;
    display: block;
    margin: 50px auto 80px;
    color: #5B1B70;
    background: #FFBC00;
    text-align: center;
    width: 260px;
}

.produtos_bota:hover {
    color: #FFBC00;
    background: #5B1B70;
}

.landing_back_escuro {
    background: #454545;
    color: #fff;
    /* padding-bottom: 100px; */
    padding-bottom: 50px;
}

.landing_back_escuro h1 {
    line-height: 27px;
    text-align: left;
    padding-top: 90px;
    font-weight: 500;
    width: 300px;
    font-size: 2em;

}

.landing_modalidades {
    display: block;
    margin: 0 auto;
}

.landing_modalidades img {
    width: 100px;
    display: block;
    margin: 80px auto 0;

    height: 100px;
}

.landing_modalidades p {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin: 40px 0;
    color: #fff;
}

.landing_back_roxo {
    background: #5B1B70;
    color: #fff;
    padding-bottom: 150px;
}

.landing_paragrafos {
    padding-top: 90px;
}

.landing_paragrafos h1 {
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 40px;
}

.landing_paragrafos p {
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    max-width: 600px;
    margin: 0 0 40px;
}

.landing_paragrafos p:last-of-type {
    font-size: 1.2em;
    line-height: 25px;
    font-weight: 500;
}

.landing_buscar {
    display: block;
    width: 300px;
    margin: 0 auto;
}

.landing_buscar img {
    width: 45px;
    background: #FFBC00;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    padding: 7px;
    float: right;
    display: inline-block;

    margin-left: 10px;
}

.landing_buscar input {
    width: 220px;
    display: inline-block;
    color: var(--preto);
    font-weight: 600;
}

.landing_back_imagem {
    height: auto;
    text-align: center;
    color: #5B1B70;
    background: url('../assets/Parallax.jpg') center center fixed;
    padding: 80px 20px;
    background-size: cover;
}

.landing_back_imagem h1 {
    line-height: 30px;
    font-size: 2em;
    font-weight: 500;
}

.landing_back_imagem p {
    text-align: right;
    margin-top: 30px;
}

.landing_porque_comecar_pq {
    color: #5B1B70;
    padding-top: 90px;
    line-height: 30px;
}

.landing_porque_comecar_pq p {
    line-height: 17px;
    color: #6F6F6F;
    margin-top: 40px;
}

.landing_back_branco {
    padding-bottom: 100px;
}

.landing_pq_article img {
    width: 100px;
    display: block;
    margin: 40px auto 20px;
    max-height: 100px;
}

.landing_pq_article h1 {
    /* font-size: 1em; */
    font-size: 1.2em;
    color: #5B1B70;
    line-height: 17px;
    display: block;
    text-align: center;
    width: 110px;
    margin: 0 auto 10px;
}

.landing_pq_article p {
    line-height: 17px;
    color: #6F6F6F;
    display: block;
    width: 150px;
    text-align: center;
    margin: 0 auto 20px;
}

.buttton_comecar button {
    border-radius: 35px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 12px 36px 12px 36px;
    display: block;
    margin: 0 auto;
    color: #5B1B70;
    background: #FFBC00;
    text-align: center;
    width: 220px;
}

.buttton_comecar button:hover {
    color: #FFBC00;
    background: #5B1B70;
}

.landing_back_footer {
    background: #454545;
    padding: 60px 0;
    display: inline-block;
}

.landing_imagem_dindin img {
    width: 100px;
    display: block;
    margin: 30px auto 50px;
}

.landing_redes_sociais {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.landing_redes_sociais img {
    width: 30px;
    margin: 0 20px;
    max-height: 30px;
}

.landing_footer_links {
    display: block;
    margin: 60px auto 30px;
}

.landing_footer_links h1 {
    color: #FFBC00;
    /* font-size: 1em; */
    font-size: 1.2em;
}

.landing_footer_links a {
    /* font-size: 0.7em; */
    font-size: 0.9em;
    color: #E8E8E8;
    display: block;
}

.landing_footer_links button {
    /* font-size: 0.7em; */
    font-size: 0.9em;
    color: #E8E8E8;
    display: block;
}

.landing_footer_a1 {
    display: inline-block;
    margin: 0 20px;
}

.landing_footer_a2 {
    display: inline-block;
    margin: 0 15px;
}

.direitos_nid {
    color: #fff;
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 280px;
}

.direitos_nid p {
    font-size: 0.7em;
}

.direitos_nid img {
    width: 130px;
    display: block;
    margin: 30px auto 0px;
}

.container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
    position: relative !important;
}

/*RESPONSIVO*/
/*720px*/
@media (min-width: 45em) {
    .container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
        position: inherit !important;
    }

    .landing_comprando h1 {
        width: 70%;
        font-size: 2.3em;
    }

    .landing_comprando p {
        /* width: 400px; */
        display: block;
        margin: 0 auto;
    }

    .landing_comprando input {
        display: inline-block;
        margin: 20px auto;
    }

    .responsive {
        display: block;
        margin: 0 auto;
    }

    .landing_comprando a {
        display: inline-block;
    }

    .landing_comprando form {
        text-align: center;
    }

    .landing_comprando input:last-of-type {
        width: 180px;
    }

    .landing_comprando input {
        margin: 0 4px;
    }

    .landing_imagem_meio img:first-of-type {
        width: 80%;
    }

    .landing_iphone img {
        margin: 0 0 0 10%;
        width: 38%;
        display: inline-block;
    }

    .landing_responsivo_caixa {
        display: inline-block;
        float: right;
    }

    .landing_chegouahora h1 {
        width: 329px;
    }

    .landing_chegouahora p {
        /* width: 290px; */
        width: 325px;
        text-align: justify;
    }

    .landing_funciona h1 {
        max-width: 300px;
        margin-top: 20px;
    }

    .produtos_bota {
        padding: 19px 30px 19px 30px;
    }

    .landing_article p {
        width: 154px;
        display: inline-block;
    }

    .landing_article {
        display: inline-block;
        margin: 0;
    }

    .landing_tudo {
        margin-top: 60px;
    }

    .landing_capsulass {
        width: 500px;
        display: block;
        margin: 0 auto;
        max-height: 1000px;
    }

    .landing_capsula {
        display: block;
        margin: 50px auto;
        width: 412px;
        text-align: center;
    }

    .landing_capsula img {
        display: inline-block;
        margin-top: -12px;
        margin-right: 15px;
        float: left;
    }

    .landing_capsula:nth-of-type(2n+0) img {
        float: right
    }

    .landing_back_escuro h1 {
        width: 350px;
    }

    .landing_modalidades {
        display: inline-block;
    }

    .landing_block_modalidades {
        display: block;
        margin: 0 auto;
        width: 100%;
        text-align: center;
    }

    .landing_modalidades img {
        width: 90px;
        display: block;
        margin: 80px 5px 0;

        height: 100px;
    }

    .landing_buscar {
        display: inline-block;
        /* width: 500px; */
        width: 560px;
        float: left;
    }

    .landing_buscar img {
        float: none;
    }

    .landing_buscar input {
        width: 400px;
    }

    .landing_pq_article {
        display: inline-block;
        margin: 0 20px;
    }

    .landing_responsive_tres {
        display: block;
        margin: 60px auto 40px;
        text-align: center;
    }

    .landing_responsive_tres2 {
        display: block;
        margin: 0 auto 80px;
        text-align: center;
    }

    .landing_redes_sociais {
        margin: 40px 0px 0px;
        float: right;
        display: inline-block;
        text-align: center;
    }

    .direitos_nid {
        float: right;
        display: inline-block;
        position: absolute;
        right: 73px;
        top: 150px;
    }

    .direitos_nid img {
        width: 130px;
        display: block;
        float: right;
        margin: 0px auto;
    }

    .direitos_nid p {
        float: right;
    }

    .landing_back_footer {
        /* padding: 0px 0 0px; */
        position: relative;
    }

    .landing_imagem_dindin img {
        width: 100px;
        position: absolute;
        left: 50%;
        margin-left: -50px;
        /* top: 10px; */
    }

    .landing_footer_links {
        display: block;
        margin: 0px auto 0px;
        position: relative;
    }

    .landing_footer_a1 {
        margin: 30px;
    }
}

.justify {
    text-align: justify
}

/*RESPONSIVO*/
/*896px*/
@media (min-width: 56em) {
    .landing_funciona p {
        width: 700px;
    }

    .landing_global_caixas {
        display: inline-block;
        margin: 0 10px;
    }

    .landing_meio_caixas {
        display: block;
        margin: 0 auto;
        text-align: center;
    }

    .landing_meio_caixas_carousel {
        /* display: block; */
        display: flex;
        position: relative;
        margin-left: 2%;
        /* margin: 0 auto; */
        /* text-align: center; */
    }

    .landing_modalidades img {
        margin: 80px 20px 0;
    }

    .landing_comprando h1 {
        font-size: 2.5em;
    }

    .landing_chegouahora h1 {
        font-size: 2.5em;
        line-height: 37px;
    }

    .landing_funciona h1 {
        font-size: 2.5em;
    }

    .landing_comprando_articles h1 {
        font-size: 2.5em;
    }

    .landing_back_escuro h1 {
        font-size: 2.5em;
        width: 500px;
    }

    .landing_paragrafos h1 {
        font-size: 2.5em;
    }

    .landing_back_imagem h1 {
        font-size: 2.5em;
        line-height: 46px;
    }

    .landing_porque_comecar_pq h1 {
        font-size: 2.5em;
    }

    .landing_paragrafos_tudo {
        margin: 0 0 0 8%;
    }

    .landing_buscar input {
        /* width: 450px; */
        width: 500px;
    }

    .landing_pq_article {
        margin: 0 57px;
    }
}

/*RESPONSIVO*/
/*1104px*/
@media (min-width: 69em) {
    .landing_global_caixas {
        margin: 0 34px;
    }

    .landing_modalidades img {
        margin: 80px 35px 0;
    }

    .landing_paragrafos_tudo {
        /* margin: 0 0 0 15%; */
        margin: 0 0 0 23%;
    }

    .landing_back_escuro h1 {
        /* margin: 0 15%; */
        margin: 0 0%;
    }

    .landing_pq_article {
        margin: 0 88px;
    }

    .landing_responsivo_caixa {
        margin-right: 10%;
    }
}

/*RESPONSIVO*/
/*1280px*/
@media (min-width: 80em) {
    .landing_comprando h1 {
        font-size: 3em;
        line-height: 50px;
    }

    .landing_chegouahora h1 {
        font-size: 3em;
        line-height: 48px;
    }

    .landing_funciona h1 {
        font-size: 3em;
        max-width: 360px;
    }

    .landing_comprando_articles h1 {
        font-size: 3em;
    }

    .landing_back_escuro h1 {
        font-size: 3em;
        width: 500px;
    }

    .landing_paragrafos h1 {
        font-size: 3em;
    }

    .landing_back_imagem h1 {
        font-size: 3em;
        line-height: 46px;
    }

    .landing_porque_comecar_pq h1 {
        font-size: 3em;
    }

    .landing_global_caixas {
        margin: 0 54px;
    }

    .landing_modalidades img {
        margin: 80px 48px 0;
    }

    .landing_pq_article {
        margin: 0px 115px;
    }
}

/* BOOT.CSS */